@import-normalize;
@import 'typography';
// @import 'amplifyOverrides';

html {
  ::selection {
    background: rgba($secondary, 0.3);
  }
  width: 100vw;
  overflow-x: hidden;
}

body {
  margin: 0;
}
