@import 'colors';
@import '~@fontsource/source-sans-pro/400.css';
@import '~@fontsource/source-sans-pro/600.css';

body {
  color: $primary;
  font-family: 'Source Sans Pro', sans-serif;
}
button,
a {
  color: $primary;
  font-family: 'Source Sans Pro', sans-serif;
  letter-spacing: 0.05em;
  text-decoration: none;
  height: 100%;
}

button:focus {
  outline: 0;
}
input {
  font-family: 'Source Sans Pro', sans-serif;
}
