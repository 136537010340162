.city {
  display: flex;
  .left {
    width: 25%;
    fieldset {
      border-radius: 4px 0 0 4px;
      border-right-color: transparent;
    }
  }
  .right {
    width: 75%;
    fieldset {
      border-radius: 0 4px 4px 0;
    }
  }
}

.country {
  display: flex;
  .left {
    width: 50%;
    fieldset {
      border-radius: 4px 0 0 4px;
      border-right-color: transparent;
    }
  }
  .right {
    width: 50%;
    fieldset {
      border-radius: 0 4px 4px 0;
    }
  }
}
