@import 'breakpoints';
@import '_colors';

.navbar {
  position: absolute;
  height: 64px;
  z-index: 999;
  background-color: #fff;
  box-shadow: $box-shadow;
  justify-content: space-between;
}

.logo {
  height: 40px;
}

.notifications {
  background-color: white;
  padding-top: 0.5rem;
  box-shadow: $box-shadow;
  border-radius: 10px;
  .notificationsHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem 0 1rem;
    label {
      margin: 0;
    }
  }
}
.notification {
  width: 100%;
  display: grid;
  grid-template-columns: 80% 10%;
  column-gap: 10%;
  p {
    margin: 0;
    line-height: 1.3em;
  }
  .date {
    opacity: 0.5;
    font-size: 0.9em;
    margin-top: 0.3rem;
  }
}
.seenContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  .seen {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 1rem;
    background-color: $secondary;
  }
}

.drawer {
  .drawerHeader {
    height: 64px;
    border-bottom: $border;
  }
  .drawerMenu {
    border-bottom: $border;
  }
  button {
    padding: 0;
    min-width: auto;
  }
}

.tools {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  .menu {
    margin-right: 2rem;
    a:first-of-type {
      margin-right: 1rem;
    }
  }
  .logout {
    margin-left: 1.5rem;
  }
}

.user {
  background-color: rgba($primary, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  span {
    opacity: 0.5;
  }
}
