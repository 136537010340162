$primary: #003169;
$secondary: #eb6617;
$error: #e4001f;

$box-shadow: 0px 0px 10px rgba($primary, 0.2);
$border: 1px solid rgba($primary, 0.1);
$border-radius: 10px;

$transition: all 0.2s ease;

$gradient-animation-bg: linear-gradient(
    70deg,
    rgba($primary, 0.04),
    rgba($primary, 0.04),
    white,
    rgba($primary, 0.04),
    rgba($primary, 0.04)
  )
  left/200%;

@keyframes gradient-animation-keyframes {
  0% {
    background-position: 0;
  }
  50% {
    background-position: -100%;
  }
  100% {
    background-position: -200%;
  }
}
$gradient-animation: gradient-animation-keyframes 2s ease-in infinite;
