.container {
  margin-bottom: 40px;
}

.link {
  display: flex;
  align-items: center;
  font-weight: 600;
  svg {
    margin-right: 0.5rem;
  }
}
