@import 'colors';

.background {
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($primary, 0.6);
}

.container {
  background-color: white;
  border-radius: $border-radius;
  padding: 1.5rem 2rem;
  display: flex;
  flex-flow: column wrap;
  max-width: 300px;
}

.heading {
  margin: 0 0 1rem 0;
}

.body {
  margin: 1.5rem 0;
  line-height: 1.5em;
}

.icons {
  align-self: center;
  svg {
    width: 2.5rem;
    height: 2.5rem;
  }
}

.input {
  margin: 1.5rem 0;
  svg {
    cursor: pointer;
  }
}

.buttons {
  height: 3.5rem;
  align-self: flex-end;
}
