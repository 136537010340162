@import 'colors';

.section {
  margin-bottom: 40px;
}
.values {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
  & > div {
    margin: 0 2rem 1rem 0;
  }
}

.inputContainer {
  margin-bottom: 1rem;
}
.double {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
}
.joined {
  display: grid;
  grid-template-columns: 1fr 1fr;
  .left {
    fieldset {
      border-radius: 4px 0 0 4px;
      border-right-color: transparent;
    }
  }
  .right {
    fieldset,
    & > div {
      border-radius: 0 4px 4px 0;
    }
  }
}

.input {
  width: 100%;
  input::placeholder {
    font-weight: 600;
  }
  label {
    opacity: 0.5;
    font-weight: 600;
  }
}

.specialLabel {
  opacity: 0.5;
  font-weight: 600 !important;
}

.kg {
  font-weight: 600;
}

.picker {
  width: 100%;
  button {
    width: 42px;
    height: 42px;
    margin-right: -8px;
  }
  label {
    opacity: 0.5;
    font-weight: 600;
  }
}

.outlined {
  border: 1px solid rgba(#000, 0.25);
  height: 100%;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.buttonRow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  height: 3.5rem;
}

.list {
  width: 100%;
  margin: 1rem 2rem;
  &.collector {
    .listLabels,
    .listItem {
      grid-template-columns: 1.5fr 1fr 1fr 3fr;
    }
  }
  &.driver {
    .listLabels,
    .listItem {
      grid-template-columns: 1.5fr 1fr 1fr 1fr 1.5fr;
    }
  }
}

.listLabels,
.listItem {
  display: grid;
  column-gap: 0.5rem;
  padding-left: 1rem;
}

.listLabels {
  align-items: flex-end;
  margin-bottom: 0.5rem;
}

.listItem {
  align-items: center;
  background-color: white;
  box-shadow: $box-shadow;
  border-radius: 4px;
  margin-bottom: 1rem;
  height: 3.5rem;
}

.fakeItem {
  margin-bottom: 1rem;
  height: 56px;
  border-radius: $border-radius;
  background: $gradient-animation-bg;
  animation: $gradient-animation;
}
