@import 'colors';

.container {
  width: 100%;
  display: flex;
  border-bottom: $border;
  margin-bottom: 1rem;
}

.button {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.8em;
  margin-bottom: -1px;
  border: 0;
  border-bottom: 2px solid transparent;
  background-color: transparent;
  transition: border-color 0.2s ease;

  &.active {
    font-weight: 600;
    border-bottom: 2px solid $primary;
    &:hover {
      border-bottom: 2px solid $primary;
    }
  }

  &:hover {
    border-bottom: 2px solid rgba($primary, 0.2);
  }
}
